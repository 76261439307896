<template>
  <section class="kontakt">
    <h1 class="kontakt__h1">
      Kontakt
    </h1>

    <div class="kontakt__grid">
      <div class="kontakt__cell kontakt__cell--a">
        <div>Bolzstraße 8</div>
        <div>70173 Stuttgart</div>
        <div>
          <LinkTel />
        </div>
        <div>
          <LinkEmail />
        </div>
        <BtnRsv class="kontakt__btn-rsv" />
      </div>
      <div class="kontakt__cell kontakt__cell--b">
        <div>Dienstag - Donnerstag</div>
        <div>11:30 - 23:00 Uhr</div>
        <div>Freitag - Samstag</div>
        <div>11:30 - 00:00 Uhr</div>
        <div>Sonntag</div>
        <div>10:00 - 23:00 Uhr</div>
      </div>
    </div>

    <div class="kontakt__grid kontakt__grid--mealtypes">
      <div class="kontakt__cell kontakt__cell--a">
        <div>Frühstück: Sonntag</div>
        <div>Lunch: Dienstag - Samstag</div>
        <div>Abendessen: Dienstag - Sonntag</div>
      </div>
      <div class="kontakt__cell kontakt__cell--b">
        <div>10:00 - 13:00 Uhr</div>
        <div>11:45 - 13:30 Uhr</div>
        <div>18:00 - 20:00 Uhr</div>
      </div>
    </div>

    <div class="kontakt__map">
      <iframe
        frameborder="0"
        style="border:0"
        :src="mapSrc"
        allowfullscreen
      />
    </div>
  </section>
</template>

<script>
import BtnRsv from "@/components/BtnRsv.vue"
import LinkTel from "@/components/LinkTel.vue"
import LinkEmail from "@/components/LinkEmail.vue"

export default {
  components: {
    BtnRsv,
    LinkTel,
    LinkEmail
  },

  data() {
    return {
      googleApikey: process.env.VUE_APP_PUBLIC_APIKEY_GOOGLE
    }
  },

  computed: {
    mapSrc() {
      // https://developers.google.com/maps/documentation/embed/start
      return `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJEX4pJTTbmUcRCwNHSMZJ0p0&key=${this.googleApikey}`
    }
  }
}
</script>

<styles lang="scss">
@use "app";
@use "img";
@use "txt";
@use "vars";

.kontakt {
  margin-top: app.$margin-to-nav;
}

.kontakt__h1 {
  @include txt.sitehead;
  @include txt.bodypadding-h;
}

.kontakt__grid {
  margin: 2em auto 0 auto;
  padding: 0 vars.$body-padding-h;
  max-width: 30em;
  display: grid;
  grid-template:
    "a b" auto /
    1fr 1fr;
  grid-gap: 3em;

  @media (max-width: vars.$break-mobile) {
    /* Re-define template to avoid autoprefixer warning. */
    grid-template:
      "a b" auto /
      1fr 1fr;
    grid-gap: 1em;
  }
}

.kontakt__grid--mealtypes {
  margin-top: 3em;
}

.kontakt__cell {
  text-align: center;

  > div {
    margin-top: 0.5em;
    &:first-child {
      margin-top: 0;
    }
  }
}

.kontakt__cell--a {
  grid-area: a;
}

.kontakt__cell--b {
  grid-area: b;
}

.kontakt__btn-rsv {
  margin-top: 1.5em;
}

.kontakt__map {
  margin: 4em 0;
  height: 100vh;
  $b: img.$gap solid img.$gap__color;
  border-top: $b;
  border-bottom: $b;

  iframe {
    display: block;
    height: 100%;
    width: 100%;
  }
}
</styles>
